<template>
  <div>
    <b-card
      no-body
      class="border-primary"
    >
      <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
        <h5 class="mb-0">
          Acciones
        </h5>
        <b-badge variant="light-primary">
          ADMIN
        </b-badge>
        <small class="text-muted w-100">{{ (new Date()).toISOString().slice(0, 19).replace(/-/g, "/").replace("T", " ") }}</small>
      </b-card-header>

      <b-card-body>
        <!-- <ul class="list-unstyled my-1"> -->
        <!-- <li>
          <span class="align-middle">5 Users</span>
        </li> -->
        <!-- <li class="my-25">
          <span class="align-middle">10 GB storage</span>
        </li>
        <li>
          <span class="align-middle">Basic Support</span>
     </li> -->
        <!-- </ul> -->

        <v-select
          v-model="status"
          disabled

          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="opcionesStatus"

          @input="(val) => changeProspectStatus(val)"
        />
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          class="mt-1"
          variant="danger"
          disabled
          @click="toggleUserStatus(1,2)"
        >
          No disponible
        </b-button>

      </b-card-body>
    </b-card>
    <div>

      <b-modal
        v-model="modalReserva"
        title="Using v-model property"
        ok-title="Accept"
        ok-only
        size="xl"
      >
        Bonbon caramels muffin.
        Chocolate bar oat cake cookie pastry dragée pastry.
        Carrot cake chocolate tootsie roll chocolate bar candy canes biscuit.
        Gummies bonbon apple pie fruitcake icing biscuit apple pie jelly-o sweet roll.
        Toffee sugar plum sugar plum jelly-o jujubes bonbon dessert carrot cake.
        Cookie dessert tart muffin topping donut icing fruitcake. Sweet roll cotton candy dragée danish Candy canes chocolate bar cookie.
        Gingerbread apple pie oat cake. Carrot cake fruitcake bear claw. Pastry gummi bears marshmallow jelly-o.
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BBadge, BButton, BModal, VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import vSelect from 'vue-select'

export default {

  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  components: {
    vSelect,
    BModal,
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BButton,
  },
  data() {
    return {
      modalReserva: false,
      status: '',
      opcionesStatus: [{ value: 1, label: 'En Revisión' }, { value: 2, label: 'Creado en Fleet Manager' }],
    }
  },

  methods: {
    toggleUserStatus(statusIn) {
      // console.log(statusIn, this.$route.params.id)
      store.dispatch('app-user/toggleUserStatus', { status: statusIn, id: this.$route.params.id })
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          // console.log(response)
        })
        .catch(error => {
          if (error.response.status === 404) {
            // console.log(error)
          }
        })
    },
    changeProspectStatus() {
      // console.log(this.status.value, ':', this.$route.params.id)

      store.dispatch('app-user/changeProspectStatus', { status: this.status, id: this.$route.params.id })
        .then(() => {
          // console.log(response)
        })
        .catch(error => {
          if (error.response.status === 404) {
            // console.log(error)
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
