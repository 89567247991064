/* eslint-disable vue/no-use-v-if-with-v-for */
/* eslint-disable vue/valid-v-for */
/* eslint-disable vue/no-unused-vars */
/* eslint-disable no-self-compare */
/* eslint-disable no-self-compare */
/* eslint-disable default-case */
/* eslint-disable no-restricted-syntax */
<template>

  <b-card
    v-ripple.400="'rgba(255, 255, 255, 0.15)'"

    title="Reservas disponibles para el día"
  >
    <p>
      <b-form-datepicker
        id="example-datepicker"
        v-model="selectedDate"
        class="mb-2"
        locale="es-CL"
        @context="changeDate"
      />

      <b-form-select
        v-if="$store.getters['app/userData'].role ==='admin'"
        v-model="selectedStatus"
        :options="postStatus"
        @change="changeStatus"
      />
    </p>
    <!-- <b-button
        class="mt-1"
        variant="primary"

        @click="crearPartners(userData.documents.responses.answers)"
      >
        Crear en Partners
      </b-button> -->

    <!-- <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      class="mt-1"
      variant="primary"

      @click="crearPartners(userData.documents.responses.answers)"
    >
      Crear en Partners
    </b-button> -->

    <b-form-group

      label-cols-sm="8"

      label-size="md"
      label-for="filterInput"
      class="mb-2"
    >
      <b-input-group size="md">
        <b-form-input
          id="filterInput"
          v-model="filter"
          type="search"
          placeholder="Ingresa texto"
        />
        <b-input-group-append>
          <b-button
            :disabled="!filter"
            @click="filter = ''"
          >
            Limpiar
          </b-button>
          <b-button

            @click="changeStatus"
          >
            Update
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form-group>

    <b-table
      :sort-by.sync="sortBy"
      :sort-desc.sync="descendiente"
      striped
      hover
      :busy.sync="isBusy"
      responsive
      :items="filteredPOsts"
      :fields="headers"
      :filter="filter"
      :filter-included-fields="filterOn"
      class="mb-0"
      show-empty
      small
      selectable
      select-mode="single"
      sticky-header="true"
      sticky-column="true"
      @row-clicked="rowClicked"
    >

      // eslint-disable-next-line vue/no-unused-vars
      // eslint-disable-next-line vue/no-unused-vars
      <template #cell(status)="data">
        <b
          v-if="data.value=='Disponible'"
          class="text-primary"
        >{{ data.value }}  </b>
        <b
          v-else
        >{{ data.value }}  </b>
      </template>
      <template #cell(reservation)="data">

        {{ data.value.provider_id }}
      </template>
      <b-table />

      <app-timeline>

        <!-- 12 INVOICES HAVE BEEN PAID -->
        <app-timeline-item
          class="mt-4"
          variant="info"
          title="EPAS"
        />
        <!-- icon="CameraIcon" -->
        <app-timeline-item
          v-for="(data,index) in posts"
          :key="index"
        >
          <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
            <h6>{{ posts[index] }} </h6>
            <small class="text-muted">{{ userData.documents.ts }}</small>
          </div>
        <!-- <p /> -->
        <!-- Tipo: {{ data.type }} -->

        </app-timeline-item>

      </app-timeline>
    </b-table>
    <b-modal
      v-model="modalReserva"
      centered
      title="Selección De Turnos"

      size="xl"
    >
      <template #modal-footer="{ ok, cancel }">

        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button
          size="sm"
          variant="success"
          @click="ok(); tomaTurno()"
        >
          OK
        </b-button>
        <b-button
          size="sm"
          variant="danger"
          @click="cancel()"
        >
          Cancelar
        </b-button>

      </template>

      <b-list-group v-if="selectedRow!=null ">
        <b-list-group-item>
          <div class="d-flex w-100 justify-content-between">
            <h5 class="mb-1">
              Información del turno
            </h5>

            <p />

          </div>
          <p class="mb-1">
            ID Turno:  <b-badge

              variant="secondary"
            >
              {{ selectedRow._id }}
            </b-badge>
          </p>
          <p class="mb-1">
            Tipo:  <b-badge

              variant="primary"
            >
              {{ selectedRow.type }}
            </b-badge>
          </p>
          <p class="mb-1">
            Descripción:  {{ selectedRow.desc }}
          </p>
          <p class="mb-1">
            Lugar:  {{ selectedRow.lugar }}
          </p>

          <p class="mb-1">
            Fecha: {{ selectedRow.date }}
          </p>
          <p class="mb-1">
            Status:  {{ selectedRow.status }}
          </p>

        </b-list-group-item>

        <b-list-group-item
          v-if="selectedRow.reservation!= undefined && rayoData!=null"
          class="mt-2"
        >
          <div class="d-flex w-100 justify-content-between">
            <h5 class="mb-1">
              Información de Rayo
            </h5>

            <p />

          </div>
          <p class="mb-1">
            Id Rayo: {{ rayoData._id }}
          </p>
          <p class="mb-1">
            Nombre Rayo:  <b-badge

              variant="secondary"
            >
              {{ rayoData.name_rayo }}
            </b-badge>
          </p>
        </b-list-group-item>

      </b-list-group>
      <span>Selecciona Status</span>
      <b-form-select
        v-model="selectedStatus"
        disabled
        class="my-1"
        :options="postStatus"
      />
      <span>Selecciona Rayo</span>
      <b-form-select
        v-model="rayoSelected"
        class="my-1"
        :options="rayos"
      />
      <b-list-group v-if="selectedRow!=null">
        <span>Selecciona Cupos</span>
        <b-form-checkbox-group
          id="checkbox-group"
          v-model="seleccCupos"
          name="seleccCupos"
        >
          <b-form-checkbox
            v-for="(item, index) in selectedRow.cupos"
            :key="index"
            :value="item._id"
          >
            {{ item.status }}
          </b-form-checkbox>
        </b-form-checkbox-group>
      </b-list-group>
    </b-modal>
  </b-card>
</template>

<script>
// import store from '@/store'
import {
  BCard, BTable, BFormGroup, BInputGroup, BInputGroupAppend, BFormInput,
  BListGroup, BListGroupItem, BFormSelect, BFormDatepicker, BBadge, BFormCheckbox, BFormCheckboxGroup,
  // BImg, BMedia, BAvatar,
  BButton,

  VBModal,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import * as moment from 'moment-timezone'
// import * as Realm from 'realm-web'
import { BSON } from 'realm-web'
// eslint-disable-next-line no-unused-vars

// eslint-disable-next-line no-unused-vars

// import {
//   // eslint-disable-next-line no-unused-vars
//   ObjectId,
// } from 'mongodb'
// import userStoreModule from '../userStoreModule'

export default {

  directives: {
    'b-modal': VBModal,
    BTable,
    Ripple,
  },
  components: {
    BInputGroup,
    BButton,
    BTable,
    BCard,
    BBadge,
    BFormGroup,
    BInputGroupAppend,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BFormSelect,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormDatepicker,
    // BImg,
    // BMedia,
    // BAvatar,
    AppTimeline,
    AppTimelineItem,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {

      epaData: null,
      rayoData: null,
      filteredPOsts: [],
      // selectedDate: moment(new Date()).format('YYYY-MM-DD'),
      selectedDate: '',
      rayoSelected: {},
      postStatus: ['Disponible', 'Reservada'],
      selectedStatus: 'Disponible',
      optionsSelectRayos: [],
      selectedRow: null,
      filter: null,
      modalReserva: false,
      filterOn: [],
      isBusy: true,
      sortBy: 'date',
      descendiente: true,
      seleccCupos: [],
      resultado: [],
      headers: [

        { key: '_id', sortable: true, label: 'ID' },
        { key: 'type', sortable: true, label: 'TIPO' },
        { key: 'desc', sortable: true, label: 'DESCRIPCIÓN' },
        { key: 'date', sortable: true, label: 'FECHA' },
        { key: 'status', sortable: true, label: 'STATUS' },

        { key: 'reservation', sortable: false, label: 'RESERVA' },

      ],
      // app: new Realm.App({ id: 'partnersrayoappcom-ucuyv' }),
      mongodb: {},
      postsDB: {},
      posts: [],
      rayos: [],
      moment,
      // swiperData: [
      //   { img: this.userData.responses },
      //   { img: this.userData.image_rayo },
      //   { img: this.userData.image_rayo },
      //   { img: this.userData.image_rayo },
      // ],
      imagesFromDocs: [],
    }
  },
  // computed: {
  //  items: () => this.selectedRow.cupos.filter(item => item.status === 'Disponible'),
  // },

  // setup() {
  //   const USER_APP_STORE_MODULE_NAME = 'postulaciones'
  //   if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
  // },
  async mounted() {
    console.log('jjej')
    this.selectedDate = this.currentDate()
    this.selectedStatus = 'Disponible'
    // eslint-disable-next-line no-underscore-dangle
    // console.log(store.getters['app/realmApp'].currentUser.customData._id)
    // eslint-disable-next-line no-underscore-dangle
    this.epaData = await store.getters['app/realmApp'].currentUser.functions.getEPAPerId(store.getters['app/realmApp'].currentUser.customData._id)[0]

    console.log(this.selectedStatus)
    // eslint-disable-next-line no-underscore-dangle
    // console.log(await store.getters['app/realmApp'].currentUser.functions.getEPAPerId(store.getters['app/realmApp'].currentUser.customData._id))
    this.mongodb = store.getters['app/realmApp'].currentUser.mongoClient('mongodb-atlas')
    // this.postsDB = await store.getters['app/realmApp'].currentUser.functions.readPosts()
    // console.log(await store.getters['app/realmApp'].currentUser.functions.readPosts())
    this.postsDB = this.mongodb.db('fleet_manager').collection('posts')
    // console.log(store.getters['app/realmApp'])
    // console.log(
    // //  await store.getters['app/realmApp'].currentUser.functions.getRayosPerEPA('5fbd027be49e6c004a1540ef'),
    // )
    this.findPosts()
    this.findRayos()

    this.setWatchs()
  },
  methods: {

    changeDate() {
      // console.log('changed!!')
      this.filteredPOsts = (this.selectedDate !== '' && this.selectedDate !== undefined && this.selectedDate != null)
        ? this.posts.filter(y => moment(y.date).format('YYYY-MM-DD') === this.selectedDate && y.status === 'Disponible')
        : this.posts

      // console.log(this.filteredPOsts)
    },
    changeStatus() {
      // console.log('changed!!')
      this.filteredPOsts = (this.selectedStatus !== '' && this.selectedStatus !== undefined && this.selectedStatus != null)
        ? this.posts.filter(y => y.status === this.selectedStatus)
        : this.posts

      // console.log(this.filteredPOsts)
    },

    // eslint-disable-next-line no-unused-vars
    updatePostContext(inIn, obIn) {
      this.posts[inIn].status = obIn.status
      this.posts[inIn].reservation = obIn.reservation
    },
    async tomaTurno() {
      console.log('-------')
      // eslint-disable-next-line no-underscore-dangle
      console.log(this.rayoSelected._id)
      console.log(this.rayoSelected.provider_rayo)
      // eslint-disable-next-line no-underscore-dangle
      console.log(this.selectedRow._id)
      console.log(this.rayoSelected.selectedStatus)
      console.log(this.rayoSelected.name_rayo)
      console.log(this.userData.documents.name_provider)
      console.log(this.seleccCupos)
      // eslint-disable-next-line no-underscore-dangle
      // console.log(BSON.ObjectID(this.rayoSelected._id).toString())
      // console.log(BSON.ObjectID(this.rayoSelected.provider_rayo).toString())
      // eslint-disable-next-line no-underscore-dangle
      // console.log(BSON.ObjectID(this.selectedRow._id).toString())
      // eslint-disable-next-line no-underscore-dangle
      await store.getters['app/realmApp'].currentUser.functions.postReservation(
        // eslint-disable-next-line no-underscore-dangle
        this.rayoSelected._id,
        this.rayoSelected.provider_rayo,
        // eslint-disable-next-line no-underscore-dangle
        this.selectedRow._id,
        this.selectedStatus,
        this.rayoSelected.name_rayo,
        this.userData.documents.name_provider,
        this.seleccCupos,
      )
        .then(() => {
          //    console.log(x)
        })
        .catch(error => {
          console.log(error)
        })

      // eslint-disable-next-line no-underscore-dangle
      this.resultado = await store.getters['app/realmApp'].currentUser.functions.checkSeats(this.selectedRow._id)
      console.log('RESULTADO GET')
      console.log(this.resultado)
      if (this.resultado.length === 0) {
        // eslint-disable-next-line no-underscore-dangle
        await store.getters['app/realmApp'].currentUser.functions.updateReservation(this.selectedRow._id, 'Reservada')
      }
      console.log(this.resultado[0].disp)

      this.findPosts()
      this.changeDate()

      this.selectedRow = null
    },
    rowClicked(f) {
      if (f.status === 'Disponible' || f.status === 'Reservada') {
        this.selectedStatus = 'Reservada'
        this.modalReserva = true
        this.selectedRow = f
        // eslint-disable-next-line no-plusplus
        console.log(f)

        this.selectedRow.cupos = this.selectedRow.cupos.filter(item => item.status !== 'Reservada')

        // eslint-disable-next-line no-underscore-dangle
        // const resu = this.rayos.filter(x => BSON.ObjectID(x._id).toString() === BSON.ObjectID(f.reservation.rayo_id).toString())[0]
        // this.rayoData = resu
        // console.log(resu)
      }
    },
    currentDate() {
      // console.log(moment().zone('Santiago/America').format('YYYY-MM-DD'))
      return moment().zone('Santiago/America').format('YYYY-MM-DD')
    },
    async  findPosts() {
      // const result = await this.postsDB.find({ }) // *****????
      this.posts = await store.getters['app/realmApp'].currentUser.functions.readPosts()
      this.isBusy = false
      this.changeStatus()
      // return result
    },
    async  findRayos() {
      // const result = await this.postsDB.find({ }) // *****????
      this.rayos = await store.getters['app/realmApp'].currentUser.functions.getRayosPerEPA('5fbd027be49e6c004a1540ef')

      for (let x = 0; x < this.rayos.length; x += 1) {
        this.rayos[x].value = this.rayos[x]
        this.rayos[x].text = this.rayos[x].name_rayo
      }
      // return result
    },
    async  setWatchs() {
      // eslint-disable-next-line no-restricted-syntax
      for await (const change of this.postsDB.watch()) {
        // eslint-disable-next-line no-restricted-syntax
        // eslint-disable-next-line default-case
        switch (change.operationType) {
          case 'insert': {
            // eslint-disable-next-line no-restricted-syntax
            // eslint-disable-next-line no-unused-vars
            const { documentKey, fullDocument } = change
            // eslint-disable-next-line no-restricted-syntax
            // console.log(`new document: ${documentKey}`, fullDocument)
            break
          }
          case 'update': {
            const { documentKey, fullDocument } = change
            // eslint-disable-next-line no-underscore-dangle
            // console.log(BSON.ObjectID(documentKey._id).toString())
            // eslint-disable-next-line no-underscore-dangle
            // const cut = this.posts.filter(k => BSON.ObjectID(k._id).toString() === BSON.ObjectID(documentKey._id).toString())
            // eslint-disable-next-line no-underscore-dangle
            const index = this.posts.findIndex(k => BSON.ObjectID(k._id).toString() === BSON.ObjectID(documentKey._id).toString())
            // console.log(index)
            // eslint-disable-next-line no-underscore-dangle
            // console.log(documentKey._id)
            // console.log(fullDocument)

            const newdOC = fullDocument
            //  this.posts[index] = newdOC
            this.updatePostContext(index, newdOC)

            // console.log(this.posts[index])

            //  console.log(`updated document: ${documentKey}`, fullDocument)
            break
          }
          case 'replace': {
            // eslint-disable-next-line no-unused-vars
            const { documentKey, fullDocument } = change
            // console.log(`replaced document: ${documentKey}`, fullDocument)
            break
          }
          case 'delete': {
            // eslint-disable-next-line no-unused-vars
            const { documentKey } = change
            // console.log(`deleted document: ${documentKey}`)
            break
          }
        }
      }
    },
    // crearPartners(dataIn) {
    //   console.log(dataIn)
    //   store.dispatch('postulaciones/createEpaPartnersServer', dataIn)
    //     .then(response => {
    //       console.log(response)
    //     })
    //     .catch(error => {
    //       if (error.response.status === 404) {
    //         console.log(false)
    //       }
    //     })

    //   return {
    //     a: false,
    //   }
    // },
    getDefinition(id) {
      // console.log(id)
      return this.userData.documents.responses.definition.fields.find(x => x.id === id)
    },
  },
}
</script>

<style>
.expan {
  width: 140px;
  max-width: 100%;
}

</style>
